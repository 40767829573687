import React, { useEffect } from 'react'
// YM
import ym, { YMInitializer} from 'react-yandex-metrika'
// RRD
import { BrowserRouter, Redirect, Route, Switch, DefaultRoute } from 'react-router-dom'
// Scroll to top
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
// Components
import NavigationBar from './components/NavigationBar/NavigationBar'
// Pages
import MainPage from './components/MainPage/MainPage'
import AboutUsPage from './components/AboutUsPage/AboutUsPage'
// Cases
import Case from './components/Case/Case'
import case1 from './components/cases/case1'
import case2 from './components/cases/case2'
import case3 from './components/cases/case3'


function App() {

    useEffect( () => {
        const getMetrika = () => {

            const bodyClick = (e) => {
                switch (e.target.getAttribute("id")) {
                    case "metrikaPhone": {
                        return ym('reachGoal','phoneclick');
                    };
                    case "metrikaMail": {
                        return ym('reachGoal','mailclick');
                    };
                    case "metrikaFormButton": {
                        return ym('reachGoal','iwantthesame');
                    };
                    case "metrikaFormImg": {
                        return ym('reachGoal','iwantthesame');
                    };
                    default:
                        return;
                }
            }
    
    
            document.body.addEventListener("click", bodyClick)
            
        }
    
        getMetrika();
    }, [] )
    


    return (
        <BrowserRouter>
            <YMInitializer accounts={[73933651]} />
            <ScrollToTop>
                <NavigationBar />
                <Switch>
                    <Route path="/case1">
                        <Case case={case1} />
                    </Route>
                    <Route path="/case2">
                        <Case case={case2} />
                    </Route>
                    <Route path="/case3">
                        <Case case={case3} />
                    </Route>
                    <Route path="/about">
                        <AboutUsPage />
                    </Route>
                    <Route path="/main">
                        <MainPage />
                    </Route>
                    <Redirect to="/main" />
                </Switch>
            </ScrollToTop>
        </BrowserRouter>
    );
}



export default App;