import React, { useEffect, useRef } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import style from './ModalWindow.module.scss'
// Formik
import { Formik, Form, Field } from 'formik'
// AOS
import Aos from 'aos';
import '../../../node_modules/aos/dist/aos.css'
// Modal
import Modal from 'react-modal'
// API
import { setText } from './../api/api'



function ModalWindow(props) {
    const _modal = useRef()


    useEffect(() => {
        // _modal.style.backgroundColor = 'red'
    })

    const onOpen = () => {
    }

    const onClose = () => {
    }

    return (
        <Modal
            className={style.modal_window}
            onAfterOpen={onOpen}
            onAfterClose={onClose}
            overlayClassName={style.background}
            ref={_modal}
            style={{
                transform: 'translateY(100%)'
            }}
            {...props}
        >
            <div className={style.swipe_button_container}>
                <div className={style.swipe_button} onClick={props.onClose} />
            </div>
            <h3 className={style.heading}>
                Оставить заявку
            </h3>
            <hr className={style.hr} />
            <Formik
                initialValues={{
                    name: "",
                    phone: "",
                    text: ""
                }}
                onSubmit={(values) => {
                    const DATA = new FormData();
                    DATA.append("name", values.name)
                    DATA.append("phone", values.phone)
                    DATA.append("text", values.comment)

                    setText(DATA)
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Field
                            name="name"
                            placeholder="Ваше имя"
                            className={style.input_area}
                            type="text"
                        />
                        <Field
                            name="phone"
                            placeholder="Ваш номер телефона"
                            className={style.input_area}
                            type="text"
                        />
                        <Field
                            component="textarea"
                            name="text"
                            placeholder="Сообщение"
                            className={style.textarea}
                            type="text"
                        />
                        <button type="submit" disabled={isSubmitting} className={style.button} onClick={props.onClose}>
                            Отправить
                        </button>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}



export default ModalWindow