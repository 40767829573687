import * as axios from 'axios'

const instanse = axios.create({
    baseURL: "http://promo.orangedm.agency/backend/"
})

export const setText = (DATA) => {
    return instanse.post(`get_mail.php`, DATA)
        .then(response => {
            return response.data
        })
}