import style from './Targets.module.scss'



function TargetItem(props) {
    return (
        <div className={style.target_item}>
            {/* <p className={style.paragraph}>
                {props.paragraph}
            </p> */}
            <p className={style.paragraph}>
                {props.children}
            </p>
            <h3 className={style.heading}>
                {props.heading}
            </h3>
            
        </div>
    );
}

function Targets() {
    return (
        <ul className={style.targets}>
            <li className={style.item}>
                <TargetItem heading="7+">
                    лет <br></br> на рынке
                </TargetItem>
            </li>
            <li className={style.item}>
                <TargetItem heading="20">
                    человек <br></br> в штате
                </TargetItem>
            </li>
            <li className={style.item}>
                <TargetItem heading="550">
                    Запущенных <br></br> Сайтов
                </TargetItem>
            </li>
            <li className={style.item}>
                <TargetItem heading="200">
                    Довольных <br></br> клиентов
                </TargetItem>
            </li>
        </ul>
    );
}



export default Targets